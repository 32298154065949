.section-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px;

}
.section-container2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;

}
@import url('https://fonts.googleapis.com/css2?family=Jaro:opsz@6..72&display=swap');


    .anton-regular {
        font-family: "Anton", sans-serif;
        font-weight: 400;
        font-style: normal;
      }
      
      .date-range-picker {
        padding: 20px;
        font-family: Arial, sans-serif;
    }
    
    .date-pickers {
        display: flex;
        gap: 1rem; /* Space between the date pickers */
    }
    
    .date-picker {
        flex: 1; /* Make the date pickers take equal space */
    }
    
    .date-picker label {
        display: flex;
        flex-direction: column;
    }
    